<template>
  <div id="FestivalShowsTemporality">
    <QsCard :qlik-ids="['KDrfTTM']" style="grid-column: 1 / span 12" />
    <QsCard :qlik-ids="['ewwJpBB']" style="grid-column: 13 / span 12" />

    <QsCard :qlik-ids="['enMdsK']" style="grid-column: 1 / span 24" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#FestivalShowsTemporality {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 400px 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
